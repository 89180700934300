

import './banner.styles.scss'

const Banner = ()=>(
    <div className="banner">
        <span className='banner_title'> Let the Traveller in you, <br /> Come Out </span>
    </div>
)

export default Banner